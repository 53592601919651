import styled from "styled-components";
import office_01 from "../../../assets/image/kanzlei/gallery/office_01.webp";
import office_02 from "../../../assets/image/kanzlei/gallery/office_02.webp";
import office_03 from "../../../assets/image/kanzlei/gallery/office_03.webp";
import office_04 from "../../../assets/image/kanzlei/gallery/office_04.webp";
import office_05 from "../../../assets/image/kanzlei/gallery/office_05.webp";
import office_06 from "../../../assets/image/kanzlei/gallery/office_06.webp";
import office_07 from "../../../assets/image/kanzlei/gallery/office_07.webp";
import office_08 from "../../../assets/image/kanzlei/gallery/office_08.webp";
import office_09 from "../../../assets/image/kanzlei/gallery/office_09.webp";
import office_10 from "../../../assets/image/kanzlei/gallery/office_10.webp";
import office_11 from "../../../assets/image/kanzlei/gallery/office_11.webp";
import office_12 from "../../../assets/image/kanzlei/gallery/office_12.webp";
import Container from "../../UI/Container/Container";

const ItemWrapper = styled.div<{ slider?: boolean }>`
  ${({ slider }) =>
    !!slider ? "padding: 0 1rem;" : "width: calc(100% / 4 - 25px * 3 / 4);"}

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    ${({ slider }) =>
      !!slider ? "padding: 0 1rem;" : "width: calc(100% / 2 - 15px / 2);"}
  }
`;

const Gallery: React.FC<{ slider?: boolean }> = ({ slider = false }) => {
  const images = [
    office_01,
    office_02,
    office_03,
    office_04,
    office_05,
    office_06,
    office_07,
    office_08,
    office_09,
    office_10,
    office_11,
    office_12,
  ];

  const getItems = () => {
    return images.map((img, i) => (
      <ItemWrapper key={i} slider={slider}>
        <img
          src={img}
          alt=""
          className="max-w-full max-h-full object-cover aspect-square"
        />
      </ItemWrapper>
    ));
  };

  return (
    <Container>
      <div className="flex flex-wrap gap-[15px] md:gap-[25px] my-16">
        {getItems()}
      </div>
    </Container>
  );
};

export default Gallery;
