import { Person } from "./types";
import diedrichWilms from "./diedrichWilms";
import friedrichSchulze from "./friedrichSchulze";
import thorstenMuellerWinterhager from "./thorstenMuellerWinterhager";
import felixFreist from "./felixFreist";
import kathrinFreist from "./kathrinFreist";
import helenaKohlmann from "./helenaKohlmann";
// import robertSchad from "./robertSchad";
// import bettinaOtto from "./bettinaOtto";
// import thomasAbel from "./thomasAbel";

const people: Person[] = [
  diedrichWilms,
  friedrichSchulze,
  kathrinFreist,
  thorstenMuellerWinterhager,
  helenaKohlmann,
  felixFreist,
  // robertSchad,
  // bettinaOtto,
  // thomasAbel,
];

export default people;
