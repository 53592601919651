import { Area } from "../../useAreas/areas/types";
import { BlogEntry, ContentSectionType } from "./types";

const data: BlogEntry = {
  id: "auskunftsfrist-des-berliner-mietendeckels",
  title: "Auskunftsfrist des Berliner Mietendeckels",
  area: Area.ITUNDDATENRECHT,
  lawyerID: "kathrin-freist",
  date: new Date("2020-04-08"),
  imageURL:
    "https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.wilms.homepage-redesign/STATIC_CUSTOM_CONTENT/blog_entry_images/Bildschirmfoto+2024-12-06+um+08.52.32.png",
  content: [
    {
      type: ContentSectionType.TEXT,
      content: [
        "Vermieter sind nach dem Berliner Mietendeckelgesetz (MietenWoG Bln) bis zum 23.04.2020 verpflichtet, ihren Mietern unaufgefordert Auskünfte über die zur Berechnung der Mietobergrenze maßgeblichen Umstände mitzuteilen, das heißt über die Merkmale, die für die Einordnung in die Mietentabelle gemäß des MietenWoG Bln maßgeblich sind, sowie für etwaige Zuschläge.",
        "Gleiche Informationspflichten treffen den Vermieter bei Abschluss eines neuen Mietvertrages nach Inkrafttreten des Mietendeckelgesetzes, d.h. seit dem 23.02.2020. Hier müssen die nachfolgenden Informationen dem Mieter vor Vertragsabschluss mitgeteilt werden.",
        "Folgende Auskünfte müssen erteilt werden:",
      ],
    },
    {
      type: ContentSectionType.ENUMERATION,
      content: [
        "Baujahr des Objektes",
        "Heizungsart",
        "Ausstattung der Wohnung mit Sammelheizung und/oder Bad ",
        "Wohnlage",
        "Anzahl der Wohnungen in dem Objekt",
        "Miete, die zum Stichtag des 18.06.2019 galt (dies gilt insbesondere für Neuvermietungen nach dem 18.06.2019)",
      ],
    },
    {
      type: ContentSectionType.TEXT,
      content:
        "Zudem muss mitgeteilt werden, welche 5 Merkmale moderner Ausstattung vorliegen; die Wohnung gilt als modern ausgestattet, wenn 3 der 5 Merkmale vorliegen:",
    },
    {
      type: ContentSectionType.ENUMERATION,
      content: [
        "schwellenlos von der Wohnung und vom Hauseingang erreichbarer Aufzug",
        "Einbauküche",
        "hochwertige Sanitärausstattung",
        "hochwertiger Bodenbelag in der überwiegenden Zahl der Wohnräume",
        "Energieverbrauchskennwert von weniger als 120 kWh/(m2a)",
      ],
    },
    {
      type: ContentSectionType.TEXT,
      content: [
        "Erteilt der Vermieter die Informationen nicht fristgerecht, so handelt er ordnungswidrig und kann mit einem Bußgeld belegt werden.",
        "Zudem kann der Mieter im Wege einer Auskunftsklage seine Ansprüche gegen den Vermieter im gerichtlichen Wege durchsetzen.",
        "Rechtlicher Fragen hierzu beantworten wir gern.",
      ],
    },
  ],
};

export default data;
