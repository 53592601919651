import { useSelector } from "react-redux";
import styled from "styled-components";
import props from "../../../../redux/props";
import Slider from "../../../UI/Slider/Slider";
import useLawyers from "../../../hooks/useLawyers/useLawyers";
import { Person } from "../../../hooks/useLawyers/lawyers/types";

const Item = styled.a<{ slider: boolean }>`
  ${({ slider }) =>
    slider === false &&
    `
  width: calc(100% / 4 - 50px * 3 / 4);
  `}
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    ${({ slider }) =>
      slider === false &&
      `
  width: calc(100% / 2 - 15px/2);
  `}
  }
`;

const People: React.FC<{
  limit?: number;
  slider?: boolean;
  people?: { image: string; person: Person }[];
}> = ({ limit = 0, slider = false, people }) => {
  const t = useSelector((s) => s[props.TRANSLATION]);
  const internalPeople = useLawyers();

  const getItems = () => {
    return (people || internalPeople).map(({ person, image }, i) =>
      i < limit || limit === 0 ? (
        <Item href={`/rechtsanwaelte/${person.id}`} slider={slider}>
          <div className={`aspect-4/5 group ${slider === true && "px-4"}`}>
            <div className="bg-white h-full md:group-hover:hidden">
              <div
                className="bg-top bg-cover w-full mx-auto h-2/3"
                style={{ backgroundImage: `url(${image})` }}
              ></div>
              <div className="h-1/3 flex flex-col items-center justify-center px-1">
                <div className="text-xs text-center text-blue hidden md:block">
                  {t.lawyers.areaPrefix}
                  {person.areas.map((a, i) => (
                    <span className="text-xs text-blue">
                      {i > 0 ? ` & ${t.areas[a]}` : t.areas[a]}
                    </span>
                  ))}
                </div>
                <div className="text-center text-md text-blue mt-2 font-bold">
                  {`${person.firstname} ${person.lastname}`}
                </div>
              </div>
            </div>
            <div className="bg-blue px-3 h-full hidden md:group-hover:block">
              <div className="h-full flex flex-col items-center justify-center gap-10 px-1">
                <div>
                  <div className="text-xs text-center text-white">
                    {t.lawyers.areaPrefix}
                    {person.areas.map((a, i) => (
                      <span className="text-xs text-white">
                        {i > 0 ? ` & ${t.areas[a]}` : t.areas[a]}
                      </span>
                    ))}
                  </div>
                  <div className="text-center text-md text-white mt-2 font-bold">
                    {`${person.firstname} ${person.lastname}`}
                  </div>
                </div>
                <div>
                  <div className="text-md text-center text-lightGray uppercase">
                    {t.lawyers.contactData}
                  </div>
                  <a
                    href={`tel:${person.phone}`}
                    className="block text-center text-sm text-white mt-2"
                  >
                    {person.phone}
                  </a>
                  <a
                    href={`mailto:${person.mail}`}
                    className="block text-center text-sm text-white"
                  >
                    {person.mail}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Item>
      ) : null
    );
  };

  if (!people && !internalPeople) return null;

  if (slider) {
    return <Slider items={getItems()} />;
  }

  return (
    <div>
      <div className="flex flex-wrap md:gap-y-20 gap-[15px] md:gap-[50px] mt-16">
        {getItems()}
      </div>
      {limit > 0 && (
        <div className="flex justify-center mt-10">
          <a
            href="/rechtsanwaelte"
            className="text-white font-bold pt-2 text-md uppercase bg-blue py-1 px-10 shadow-md mt-5"
          >
            {t.lawyers.showAllCTA}
          </a>
        </div>
      )}
    </div>
  );
};

export default People;
