import { Person } from "./types";
import { Area } from "../../useAreas/areas/types";

const data: Person = {
  id: "helena-kohlmann",
  firstname: "Helena",
  lastname: "Kohlmann",
  title: "Rechtsanwältin",
  description: "",
  areas: [Area.MIETUNDWOHNUNGSEIGENTUMSRECHT],
  subAreas: [Area.MIETUNDWOHNUNGSEIGENTUMSRECHT],
  phone: "030 / 75 79 64 0",
  mail: "helena.kohlmann@wilms.eu",
  location: "Berlin-Tempelhof",
  vita: [""],
  memberships: [""],
};

export default data;
