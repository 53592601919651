import { Area } from "../../useAreas/areas/types";
import { BlogEntry, ContentSectionType } from "./types";

const data: BlogEntry = {
  id: "bussgelder-in-der-virus-krise",
  title: "Bußgelder in der Virus-Krise",
  area: Area.ITUNDDATENRECHT,
  lawyerID: "kathrin-freist",
  date: new Date("2020-04-04"),
  imageURL:
    "https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.wilms.homepage-redesign/STATIC_CUSTOM_CONTENT/blog_entry_images/Bildschirmfoto+2024-12-06+um+08.52.32.png",
  content: [
    {
      type: ContentSectionType.TEXT,
      content: [
        "Berliner Senat erlässt Bußgeldkatalog für Verstöße gegen die SARS-CoV-2-Eindämmungsmaßnahmenverordnung!",
        "Seit dem 3. April 2020 können bei Verstößen gegen die vom Senat erlassenen Maßnahmen im Kampf gegen Corona Bußgelder verhängt werden.",
        "Für den Fall eines Verstoßes gegen die Maßnahmenverordnung legt der Bußgeldkatalog im Einzelfall einen Bußgeldrahmen fest, wie z.B.",
      ],
    },
    {
      type: ContentSectionType.ENUMERATION,
      content: [
        "Aufenthalt außerhalb der Wohnung ohne triftigen Grund kann mit 10 bis 100 Euro geahndet werden.",
        "Wird ein Restaurant geöffnet, so liegt der Bußgeldrahmen von 1.000 bis 10.000 Euro.",
        "Die unerlaubte Vermietung von Wohnungen zu touristischen Zwecken liegt zwischen 1.000 und 10.000 Euro.",
      ],
    },
    {
      type: ContentSectionType.TEXT,
      content: "Link zum Bußgeldkatalog: https://lnkd.in/d6yUqn6",
    },
  ],
};

export default data;
