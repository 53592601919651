import Layout from "../UI/Layout/Layout";
import Hero from "../UI/Hero/Hero";
import hero from "../../assets/image/contact/hero.png";
import image from "../../assets/image/contact/image.png";
import { useSelector } from "react-redux";
import props from "../../redux/props";
import Container from "../UI/Container/Container";
import Headline from "../UI/Headline/Headline";
import Checklist from "../Home/Checklist/Checklist";
import ContactSection from "./ContactSection/ContactSection";
// import Map from "../Map/Map";
import Quote from "./Quote/Quote";

const Contact = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);

  return (
    <Layout authLevelRequired={false}>
      <Hero
        image={hero}
        subtitle={t.contact.hero.subtitle}
        title={t.contact.hero.title}
        fullWidthUnderline
        small
      />
      <Container>
        <div className="flex gap-10 md:gap-20 py-14 md:py-28">
          <div className="flex-1">
            <Headline
              prefix={t.contact.intro.headlinePrefix}
              text={t.contact.intro.headline}
              subline={t.contact.intro.subline}
            />
            <div className="mt-10 md:mt-20">
              <div>{t.generic.company.street}</div>
              <div>{t.generic.company.city}</div>
            </div>
            <div className="mt-5">
              <div>{t.contact.openHoursText}</div>
              <div>
                {t.contact.openHours.map((o, i) => (
                  <div key={i}>{o}</div>
                ))}
              </div>
            </div>
            <div className="mt-5">
              <div>
                {t.generic.phoneShortPrefix}
                {t.generic.company.phone}
              </div>
              <div>
                {t.generic.faxShortPrefix}
                {t.generic.company.fax}
              </div>
            </div>
          </div>
          <div className="flex-1 hidden md:block">
            <img src={image} alt="" className="w-full" />
          </div>
        </div>
      </Container>
      <div className="border-t-2 border-blue w-1/3 mx-auto"></div>
      <Checklist />
      <ContactSection />
      {/* <Map /> */}
      <Quote />
    </Layout>
  );
};

export default Contact;
